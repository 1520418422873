/*
  tailwind.css
    - The file you can modify (but why would you need to anyways).

  main.css
    - Is autogenerated during the build process from tailwind.css and should not be manually edited.
*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

